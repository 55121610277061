import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';

@Service()
export default class ShortcodeConnectingRequestService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async getHistory(
        operator: string,
        name: string,
        connectivityStatus: number,
        requestStatus: number): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/short-code-bulk-activation/get-short-code-connectivity-history?operatorId=' + operator
                + '&name=' + name
                + '&connectivityStatus=' + connectivityStatus
                + '&requestStatus=' + requestStatus;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async get(
        operator: string,
        name: string,
        pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/shortcodes?operatorId=' + operator
                + '&name=' + name
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getAll(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/shortcodes/getall';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async getById(id: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + `api/short-code-bulk-activation/get-by-id?id=${id}`;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async post(shortCodeId: string) {
        this.loaderService.ShowLoader();
        try {
            const model = {
                shortCodeId: shortCodeId,
            };
            const url = window.$config.HostName + 'api/short-code-bulk-activation';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
}