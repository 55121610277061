<div class="w-100 h-100">
    <div class="row" style="padding-top: 59px;">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-header justify-content-between align-items-center">
                    <label class="card_header_text">
                        {{$t('shared.senderRequestApprovalHistory')}}
                    </label>
                    <router-link :to="'/SenderRequestSupport'" tag="a" class="mx-1">
                        <button class="btn btn-primary btn-action">
                            {{$t('shared.back')}}
                        </button>
                    </router-link>
                </div>

                <div class="col-md-12 row pt-4">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>{{$t('shared.requestStatus')}}</label>
                            <v-autocomplete v-model="model.requestStatus"
                                            :items="senderRequestStatusOptions"
                                            item-text="name"
                                            item-value="id"
                                            :label="$t('shared.selectStatus')"
                                            solo>
                            </v-autocomplete>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <label>{{$t('shared.historyDate')}}</label>
                            <v-menu v-model="historyDateFromMenu" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="input-group mb-3">
                                        <input class="form-control border-right-0 border-left" v-model="model.historyDate " readonly
                                               v-bind="attrs" ref="createdDatePicker" v-on="on" type="text" />
                                        <div class="input-group-prepend">
                                            <span class="input-group-text right gray">
                                                <i class="far fa-calendar-alt"></i>
                                            </span>
                                        </div>
                                    </div>
                                </template>
                                <v-date-picker v-model="model.historyDate" no-title scrollable @input="historyDateFromMenu = false">
                                </v-date-picker>
                            </v-menu>
                        </div>
                    </div>
                    <div class="md-3 pt-5">
                        <div class="col-auto pull-left">
                            <button class="btn btn-primary btn-action"
                                    @click="showEditSenderDialog()"
                                    :disabled="!isValidEdit()">
                                {{$t('shared.apply')}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <template>
                        <v-data-table :headers="headers"
                                      :items="senderRequestApprovalHistory"
                                      :total-items="senderRequestApprovalHistoryCount"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                    <td class="text-center">{{ props.item.rejectionReason }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="editSenderDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.addHistory')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidedSenderDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.addHistoryInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hidedSenderDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="save">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>