<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('bulkshortPage.shortCodeConnectivityRequest')}}
                    </label>
                </div>
                <div class="col-auto">
                    <router-link to="/ShortcodeConnectingRequest/AddNewConnectivity" tag="button" class="btn btn-primary"><span class="text_sm_hidden">{{$t('bulkshortPage.addNewConnectivity')}}</span><i class="fas fa-plus px-2"></i></router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.operator"
                                       :placeholder="$t('shortPage.operatorSearch')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.name"
                                       :placeholder="$t('shortPage.shortcodeSearch')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <v-select :label="$t('bulkshortPage.selectConnectivityStatus')"
                                          v-model="filterModel.connectivityStatus"
                                          :items="connectivityStatusOptions"
                                          item-text="name"
                                          item-value="id"
                                          @change="search()"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <v-select :label="$t('bulkshortPage.selectRequestStatus')"
                                          v-model="filterModel.requestStatus"
                                          :items="requestStatusOptions"
                                          item-text="name"
                                          item-value="id"
                                          @change="search()"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <template>
                        <v-data-table :headers="headers"
                                      :items="shorts"
                                      hide-actions
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ getOperatorText(props.item.operatorId) }}</td>
                                    <td class="text-center">{{getShortCodeType(props.item.shortCodeType)}}</td>
                                    <td class="text-center">{{ props.item.shortCode }}</td>
                                    <td class="text-center">{{getConnectivityStatusText(props.item.connectivityStatus)}}</td>
                                    <td class="text-center">{{ getRequestStatusText(props.item.requestStatus) }}</td>
                                    <td class="text-center">
                                        <i v-if="userRole === 5||userRole === 2" class="fas fa-info-circle Icon_Info" @click="showDetailsDialog(props.item.id)"></i>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="detailsDialog" persistent width="554">
            <v-card class="overflow-hidden">
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('bulkshortPage.linkInfo')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDetailsDialog"></i>
                    </div>
                </v-card-title>
                <div class="row md-12 pt-3">
                    <div class="col-md-3">
                        <div class="col-sm-4 mb-5">
                            <label class="user_info_header mb-0">{{$t('bulkshortPage.operatorName')}}</label>
                            <h4 class="user_info_txt">{{details.operatotrName}}</h4>
                        </div>
                        <div class="col-sm-4 mb-5">
                            <label class="user_info_header mb-0">{{$t('bulkshortPage.shortCode')}}</label>
                            <h4 class="user_info_txt">{{details.shortCode}}</h4>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="col-sm-4 mb-5">
                            <label class="user_info_header mb-0">{{$t('bulkshortPage.senderTotal')}}</label>
                            <h4 class="user_info_txt">{{details.senderTotal}} </h4>
                        </div>
                        <div class="col-sm-4 mb-5">
                            <label class="user_info_header mb-0">{{$t('bulkshortPage.senderSkipped')}}</label>
                            <h4 class="user_info_txt">  {{details.senderSkipped}}</h4>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <Progress :transitionDuration="1000" :radius="80" :strokeWidth="10" :strokeColor="checkColor()" :value="getRate()">
                            <div class="content">
                                <p>
                                    {{details.senderLeft}} {{$t('bulkshortPage.of')}} {{details.senderTotal}}
                                </p>
                                <p> {{$t('bulkshortPage.senderLeft')}}</p>
                            </div>
                        </Progress>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="hideDetailsDialog">{{$t('bulkshortPage.ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>