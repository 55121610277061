<div class="w-100 h-100">
    <div class="d-flex justify-content-center">
        <div class="box pb-3">
            <div class="card-box bg-blue" @click="filter('0', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.all')}}<br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.all}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-pink" :class="{'active': requestStatus === '1'}" @click="filter('1', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.pendingFor')}} <br /> {{$t('dashboardPage.approval')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.pendingForApproval}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.pendingForApprovalPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-green" :class="{'active': requestStatus === '2'}" @click="filter('2', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.approved')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.approved}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.approvedPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-red" :class="{'active': requestStatus === '3'}" @click="filter('3', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.rejected')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.rejected}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.rejectedPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-gray" :class="{'active': requestStatus === '4'}" @click="filter('4', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.paid')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.paid}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.paidPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-parpol" :class="{'active': senderStatus === '4'}" @click="filter('0', '4')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.deactivated')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.deactivated}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">&nbsp;</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-dark-blue" :class="{'active': senderStatus === '5'}" @click="filter('5', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.expired')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.expired}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">&nbsp;</div>
            </div>
        </div>
    </div>
    <div class="row row-table">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-header">
                    <div class="form-row justify-content-between align-items-center w-100">
                        <div class="col-auto">
                            <label class="card_header_text">
                                <i class="fas fa-sync-alt reload" @click="reload()"></i>
                                {{$t('dashboardPage.requests')}} ({{sendersCount}})
                            </label>
                        </div>
                        <div class="col-md-3">
                            <div v-if="userRole == 2 || userRole == 5" class="input-group">
                                <input type="text" placeholder="Sender name" v-model="senderName" class="form-control">
                                <button class="btn btn-primary btn-action" :disabled="!senderName" @click="checkSenderStatus()">{{$t('dashboardPage.checkSender')}}</button>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button v-if="userRole == 2 || userRole == 5 && reportOptionsModel.isExportExcelForProviderEnabled" class="btn btn-primary btn-action" @click="exportToExcel()">{{$t('dashboardPage.exportToExcel')}}</button>
                            <button v-if="((userRole == 2 || userRole == 5) && !providerLocked && !providerExpired)" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showresendDialog('')">{{$t('dashboardPage.resend')}}</button>
                            <button v-if="userRole == 2 || userRole == 5" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="PreparePay('')">{{$t('dashboardPage.Pay')}}</button>
                            <button v-if="requestsOptions.isRenewRequstEnabled && (userRole == 2 || userRole == 5)" :disabled="isAnySelected" @click="renewRegisterSender()" class="btn btn-primary btn-action">{{$t('dashboardPage.renew')}}</button>
                            <router-link v-if="((userRole == 2 || userRole == 5) && !providerLocked  && !providerExpired)&& requestsOptions.isNewRequestEnabled" to="/Dashboard/AddRequest" tag="button" class="btn btn-primary btn-action">{{$t('dashboardPage.newRequest')}} <i class="fas fa-plus px-2"></i></router-link>
                            <button v-if="userRole == 1 || userRole == 9" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showapproveDialog('')">{{$t('dashboardPage.Approve')}}</button>
                            <button v-if="userRole == 1 || userRole == 9" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showRejectDialog('')">{{$t('dashboardPage.reject')}}</button>
                            <button v-if="userRole == 1 || userRole == 9" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showDeactivateDialog('')">{{$t('dashboardPage.deactivate')}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.requestId"
                                       @keypress="search"
                                       :placeholder="$t('dashboardPage.requestId')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.senderName"
                                       @keypress="search"
                                       :placeholder="$t('dashboardPage.senderNameSearch')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       @keypress="search"
                                       v-model="filterModel.clientName"
                                       :placeholder="$t('dashboardPage.clientName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="(userRole != 2  && userRole != 5)" class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       @keypress="search"
                                       v-model="filterModel.providerName"
                                       :placeholder="$t('dashboardPage.providerName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 instead-box">
                            <v-select v-model="filterModel.status"
                                      :items="statusOptions"
                                      item-text="name"
                                      item-value="id"
                                      :change="search"
                                      :clearable="true"
                                      :label="$t('shared.selectStatus')"
                                      solo>
                            </v-select>
                        </div>
                    </div>
                    <template>
                        <v-data-table v-model="selected"
                                      :headers="headers"
                                      :items="senders"
                                      :total-items="sendersCount"
                                      select-all
                                      :pagination.sync="pagination"
                                      @update:pagination="updatePaginate"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      item-key="requestId"
                                      class="elevation-1 table-striped">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="selectAll"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                                        @click="changeSort(header.value)" :width="header.width !== ''? header.width : ''">
                                        <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr>
                                    <td>
                                        <v-checkbox v-model="props.selected"
                                                    @change="select($event, props.item.id)"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>

                                    <td class="text-center">
                                        <v-tooltip bottom v-if="props.item.isScamSender">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">
                                                    {{ props.item.requestId }}
                                                    <v-icon v-if="props.item.isScamSender &&(userRole == 1 || userRole == 3
                                                        || userRole == 4|| userRole == 9)" larg style="color:red">warning_amber</v-icon>
                                                </span>
                                            </template>
                                            <span>The sender name may be considered suspecious</span>
                                        </v-tooltip>
                                        <span v-if="!props.item.isScamSender">{{ props.item.requestId }}</span>
                                    </td>
                                    <td class="text-center">{{props.item.senderName}}</td>
                                    <td class="text-center">{{ props.item.clientName }}</td>
                                    <td class="text-center" v-if="(userRole != 2  && userRole != 5)">{{ props.item.providerName }}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="status_txt" :class="'status-' + props.item.requestStatus">{{GetSenderStatus(props.item.requestStatus)}}</div>
                                            <Progress :transitionDuration="1000" :radius="10" :strokeColor="checkColor(props.item.requestStatus)" :strokeWidth="5" :value="getRate(props.item.remainingDays,props.item.totalDays)">
                                                <div class="content"></div>
                                                <template v-slot:footer>
                                                    <b></b>
                                                </template>
                                            </Progress>
                                            <div class="days_left">
                                                {{props.item.remainingDays}} {{$t('dashboardPage.of')}} {{props.item.totalDays}} <br /> {{$t('dashboardPage.daysLeft')}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center btn_menu">
                                        <template>
                                            <v-menu offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="btn-primary"
                                                           v-on="on">
                                                        <div>{{$t('shared.actions')}}</div>
                                                        <i class="fas fa-chevron-down pl-4" style="vertical-align: sub;"></i>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-tile v-if="(userRole == 2 || userRole == 5 )&& props.item.requestStatus == '2'" @click="PreparePay(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.Pay')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="((userRole == 1 || userRole == 9) && props.item.requestStatus == '1' && ((userRole == 1 && props.item.tenantId == tenantId) || userRole != 1)) || ((userRole == 1 || userRole == 9) && props.item.requestStatus == '3')"
                                                                 @click="showapproveDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.Approve')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 1  || userRole == 9) && ((userRole == 1 && props.item.tenantId == tenantId) || userRole != 1) && (props.item.requestStatus == '2' ||props.item.requestStatus == '4'||props.item.requestStatus == '5' ) && ( props.item.senderStatus != 4 && props.item.senderStatus != 6)"
                                                                 :to="'/Dashboard/EditSender/' + props.item.id ">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.edit')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 1  || userRole == 9) && ((userRole == 1 && props.item.tenantId == tenantId) || userRole != 1) && (props.item.requestStatus == '4' || props.item.requestStatus == '5'||props.item.requestStatus == '6') && ( props.item.senderStatus != 4)" @click="showDeactivateDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.deactivate')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="((userRole == 1 || userRole == 9) && ((userRole == 1 && props.item.tenantId == tenantId) || userRole != 1) && props.item.requestStatus == '1') ||
                                                                        ((userRole == 1 || userRole == 9) && props.item.requestStatus == '2')" @click="showRejectDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.reject')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if=" (userRole == 1  || userRole == 9) && ((userRole == 1 && props.item.tenantId == tenantId) || userRole != 1)  && (props.item.requestStatus == '4' || props.item.requestStatus == '2') && props.item.isCritical !== true" @click="showDeleteDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.delete')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 2 || userRole == 5) && !providerLocked && props.item.requestStatus == '3'" @click="showresendDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.resend')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile @click="Details(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.details')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="((userRole == 2 || userRole == 5 ) && (props.item.requestStatus == '2' ||props.item.requestStatus == '4') && (props.item.senderStatus == '2' || (props.item.senderStatus == '7'))) && requestsOptions.isChangeSenderTypeEnabled" @click="ChangeSenderType(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.changeSenderType')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="resendDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.resendInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideresendDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="resendComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.resendInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideresendDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="resend('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="approveDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.approveInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideapproveDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="approveComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.approveInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideapproveDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="approve('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="deactivateDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deactivateInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeactivateDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.deactivateInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeactivateDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deactivate">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="deleteDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deleteInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeleteDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text">
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="deleteComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.deletesenderInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeleteDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deleteSender">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="rejectdialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('dashboardPage.reject')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideRejectDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>

                    <div class="row mt-3">
                        <div class="col-md-12">
                            <v-autocomplete :label="$t('dashboardPage.rejectreason')"
                                            :items="popUpRejectionReasons"
                                            return-object
                                            item-value="id"
                                            @input="handleItemChange"
                                            v-model="selectedRejectReason"
                                            item-text="rejectionText">

                            </v-autocomplete>

                            <div class="form-group" v-if="isItOtherReason">
                                <v-textarea clearable
                                            clear-icon="mdi-close-circle"
                                            bg-color="#dee2e6  !important"
                                            no-resize
                                            maxlength="80"
                                            rows="3"
                                            :label="$t('dashboardPage.rejectreason')"
                                            required
                                            @display=""
                                            :rules="rules"
                                            variant="filled"
                                            v-model="rejectResone">
                                </v-textarea>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideRejectDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" :disabled="!rejectReasonIsValid" @click="reject">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="checkSenderDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideCheckSenderdialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row text-center">
                        <div class="col-12">
                            <i class="fas fa-check-circle fa-lg" style="color: #1bb148;font-size:100px;padding-bottom: 10px;"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.senderName')}}</label>
                                <h4 class="user_info_txt">{{senderStatusModel.senderName}}</h4>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('shared.senderType')}}</label>
                                <h4 class="user_info_txt">
                                    <span v-for="(type, index) in senderType" :key="type">
                                        {{getSenderTypeText(type)}}
                                    </span>
                                </h4>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.clientName')}}</label>
                                <h4 class="user_info_txt">{{senderStatusModel.clientName}}</h4>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('shared.senderStatus')}}</label>
                                <h4 class="user_info_txt">{{senderStatusModel.senderStatus}}</h4>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label v-if="senderStatusModel.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                <label v-if="senderStatusModel.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                <label v-if="senderStatusModel.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                <label v-if="senderStatusModel.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                <h4 class="user_info_txt">{{senderStatusModel.crNumber}}</h4>
                            </div>
                            <div class="form-group" v-if="senderStatusModel.customerType === 2 || senderStatusModel.customerType === 3 ">
                                <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                <h4 class="user_info_txt">{{senderStatusModel.enterpriseUnifiedNumber}}</h4>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</div>

