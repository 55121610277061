import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { SenderModel } from '../../../AddRequest/SenderModel';
import { RenewRequestModel } from './RenewRequestModel';

@Service()
export default class RenewRegisterSenderService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Post(model: SenderModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/renew';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetSenderForRenew(model: any): Promise<any> {       
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/get-sender-for-renew';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: model,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async AddExtendRequest(model: RenewRequestModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/add-extend-duration-request';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}