import Progress from 'easy-circular-progress';
import FileSaver from 'file-saver';
import moment from 'moment';
import { Inject } from 'vue-di-container';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Toaster } from '../../../Services/toast';
import { SenderType } from '../../../shared/Enums/SenderType';
import UserRoleService from '../../../shared/userService/UserRoleService';
import UserService from '../../../shared/userService/UserService';
import { AttachmentModel } from '../../AddRequest/AttachmentModel';
import DashboardService from '../../Dashboard/DashboardService';
import UpdateDataRequestsService from '../../Dashboard/UpdateDataRequests/UpdateDataRequestsService';
import { RejectionReasonModel } from '../../RejectionReason/RejectionReasonModel';
import { SenderModel } from '../SenderModel';
import WithRender from './SenderInfo.html';
import SenderInfoService from './SenderInfoService';
import { SenderRequestApprovalHistoryViewModel } from './SenderRequestApprovalHistoryViewModel';
import { SenderRequestExtendViewModel } from './SenderRequestExtendViewModel';

@WithRender
@Component({
    components: {
        Progress,
    }, diProvide: [
        UpdateDataRequestsService,
        DashboardService,
        UserService,
        SenderInfoService,
        UserRoleService,
    ],
})
export default class SenderInfo extends Vue {
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    @Prop({ type: Boolean, default: false })
    public isFromDashboard: boolean | any;
    @Prop({ type: String, default: '' })
    public id: string | any;
    public tenantId: string | any;
    public resendComment: string = '';
    public rejectResone: string | null = null;
    public otherRejectResone: string = ''
    public selectedRejectReason: RejectionReasonModel = {} as RejectionReasonModel;
    public selectedRejectReasonList: RejectionReasonModel[] = {} as RejectionReasonModel[];
    @Inject(UpdateDataRequestsService) public UpdateDataRequestsService!: UpdateDataRequestsService;
    @Inject(DashboardService) public dashboardService!: DashboardService;
    @Inject(SenderInfoService) public senderInfoService!: SenderInfoService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    public isItOtherReasonPorp: boolean = false;
    public sender: SenderModel = {
        id: '',
        duration: '',
        senderType: 0,
        newSenderType: 0,
        senderStatus: 0,
        senderId: '',
        startDate: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        providerName: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
        senderRequestAttachments: [],
        senderName: '',
        requestStatus: 0,
        isAlreadyExist: false,
        lastRejectionReason: '',
        remainingDays: 0,
        totalDays: 0,
        comment: '',
        isScamSender: false,
        isCriticalSender: false,
        tenantId: '',
        expiryDate: '',
        verificationType: 0,
        crExpiryDate: '',
        companyName: '',
        representativeID: '',
        authorizationExpiryDate: '',
    };
    public providerLocked: boolean = false;
    public providerExpired: boolean = false;
    public deleteDialog: boolean = false;
    public rejectdialog: boolean = false;
    public isAllSelected: boolean = true;
    public isOtherSelected: boolean = false;
    public showSenderRequestTransactionsdialog: boolean = false;
    public showExtendRequestsdialog: boolean = false;
    public deactivateDialog: boolean = false;
    public approveDialog: boolean = false;
    public resendDialog: boolean = false;
    public senderType: SenderType[] = [];
    public SenderRequestTransactions: SenderRequestApprovalHistoryViewModel[] = [];
    public senderRequestExtendViewModel: SenderRequestExtendViewModel[] = [];
    public selectedIds: string[] = [];
    public userRole: any;
    public customerType: string = '';
    public approveComment: string = '';
    public deleteComment: string = '';
    public User: any;
    public popUpRejectionReasons: RejectionReasonModel[] = [];
    public headers: any = [
        { text: this.$t('senderInfo.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('senderInfo.createdDate'), value: 'createdDate', align: 'center', sortable: false },
        { text: this.$t('senderInfo.comment'), value: 'rejectionReason', align: 'center', sortable: false },
    ];
    public extendRequestHeaders: any = [
        { text: this.$t('senderInfo.extendRequestId'), value: 'extendRequestId', align: 'center', sortable: false },
        { text: this.$t('senderInfo.duration'), value: 'duration', align: 'center', sortable: false },
        { text: this.$t('senderInfo.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('senderInfo.createdDate'), value: 'createdDate', align: 'center', sortable: false },
    ];

    public async mounted() {
        this.selectedIds.push(this.id);
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.userRole = await this.userRoleService.GetUserRole(this.User);
        this.tenantId = this.User.details.TenantId;
        this.isFromDashboard = true;
        this.sender = await this.senderInfoService.GetById(this.id);
        this.userRole = this.GetUserRole();
        this.customerType = this.getCustomerTypeText(this.sender.customerType);
        this.getSenderType();
        if (this.userRole === 2 || this.userRole === 5) {
            this.isProviderLocked();
        }
        if (this.userRole === 1 || this.userRole === 9) {
            await this.getRejectionReasons();
        }
        this.selectAllToggle();
    }

    public SelectOtherToggle() {
        this.$nextTick(() => {
            if (this.isOtherSelected) {
                this.otherRejectResone = ''
                this.isOtherSelected = false;
                this.isItOtherReasonPorp = false;
            } else {
                this.isOtherSelected = true;
                this.isItOtherReasonPorp = true;
            }
        });
    }
    public get isItOtherReason(): boolean {
        return this.isItOtherReasonPorp;
    }
    public async isProviderLocked() {
        const response = await this.dashboardService.isProviderLocked();
        this.providerLocked = response.result;
    }
    public async isProviderExpired() {
        const response = await this.dashboardService.isProviderExpired();
        this.providerExpired = response.result;
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async getRejectionReasons() {
        const response = await this.dashboardService.getSenderNameRejectReasons(1);
        this.popUpRejectionReasons = response.result;
    }
    public get icon() {
        if (this.isAllSelected && this.selectedRejectReasonList.length === this.popUpRejectionReasons.length) {
            return 'check_box';
        }
        if (this.selectedRejectReasonList.length > 0) {
            return 'indeterminate_check_box';
        }
        return 'check_box_outline_blank';
    }
    public get OtherIcon() {
        if (this.isOtherSelected) {
            return 'check_box';
        }
        return 'check_box_outline_blank';
    }
    public selectAllToggle() {
        this.$nextTick(() => {
            if (this.isAllSelected) {
                this.selectedRejectReasonList = [];
                this.rejectResone = null;
                this.isAllSelected = false;
            } else {
                this.isAllSelected = true;
                this.rejectResone = this.popUpRejectionReasons.slice().map((x) => x.rejectionText).toString();
                this.selectedRejectReasonList = this.popUpRejectionReasons.slice();
            }
        });
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public getSenderType() {
        const senderBinary = this.sender.senderType;
        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }
    public showDeactivateDialog() {
        this.deactivateDialog = true;
    }

    public hideDeactivateDialog() {
        this.deactivateDialog = false;
    }
    public async deactivate(id: string) {
        const response = await this.dashboardService.deactivate(this.selectedIds);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.deactivateDialog = false;
            this.reload();
        } else {
            this.deactivateDialog = false;
            this.selectedIds = [];
            Toaster.success(this.$t('dashboardPage.deactivatedsuccessfully').toString());
            this.reload();
        }
    }
    public showDeleteDialog(id: string) {
        this.deleteDialog = true;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public async deleteSender() {
        let ids: string[] = [];
        const response = await this.dashboardService.delete(this.sender.id, this.deleteComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.deleteDialog = false;
            this.reload();
        } else {
            this.deleteDialog = false;
            this.dashboardService.deleteRequestEmail(this.selectedIds);
            this.selectedIds = [];
            Toaster.success(this.$t('shared.deleteSuccessfully').toString());
            this.reload();
        }
    }
    public async reload() {
        this.selectedIds.push(this.id);
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.userRole = this.userRoleService.GetUserRole(this.User);
        if (this.userRole === 2 || this.userRole === 5) {
            this.isProviderLocked();
        }
        this.isFromDashboard = true;
        this.tenantId = this.User.details.TenantId;
        this.sender = await this.senderInfoService.GetById(this.id);
        this.userRole = this.GetUserRole();
        this.customerType = this.getCustomerTypeText(this.sender.customerType);
        this.getSenderType();
    }
    public checkColor(typeStatus: number) {
        switch (typeStatus) {
            case 1: {
                return '#c427ba';
                break;
            }
            case 2: {
                return '#5aaa0b';
                break;
            }
            case 3: {
                return '#C42727';
                break;
            }
            case 4: {
                return '#aaa7a7';
                break;
            }
        }
    }
    public GetSenderStatus(typeStatus: number) {
        switch (typeStatus) {
            case 1: {
                return 'Pending Approval';
                break;
            }
            case 2: {
                return 'Approved';
                break;
            }
            case 3: {
                return 'Rejected';
                break;
            }
            case 4: {
                return 'Paid';
                break;
            }
            case 5: {
                return 'Expired';
                break;
            }
            case 6: {
                return 'Deleted';
                break;
            }
            case 9: {
                return 'Pending For Verify';
                break;
            }
        }
    }

    public getRate(startDate: string, duration: string) {
        return (this.remainingDays(startDate, duration) / (parseInt(duration, 10) * 365)) * 100;
    }

    public remainingDays(startDate: string, duration: string): number {
        const todaysdate = moment();
        const expiryDate = moment(startDate).add(parseInt(duration, 10), 'years').calendar();
        const eventdate = moment(expiryDate);
        if (moment(moment(startDate).format('L')).isAfter(moment().format('L'))) {
            return eventdate.diff(moment(startDate), 'days') + 1;
        } else {
            return eventdate.diff(todaysdate, 'days') + 1;
        }
    }

    public expiryDate(startDate: string, duration: string): number {
        const expiryDateString = moment(startDate).add(parseInt(duration, 10), 'years').calendar();
        const eventdate = moment(startDate);
        const expiryDate = moment(expiryDateString);
        return expiryDate.diff(eventdate, 'days') + 1;
    }
    public showresendDialog() {
        this.resendDialog = true;
    }
    public hideresendDialog() {
        this.resendComment = '';
        this.resendDialog = false;
    }
    public showapproveDialog() {
        this.approveComment = '';
        this.approveDialog = true;
    }
    public hideapproveDialog() {
        this.approveDialog = false;
    }
    public async approve() {
        if (this.sender.requestStatus !== 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
            return;
        } else {
            await this.dashboardService.approve(this.selectedIds, this.approveComment);
            Toaster.success(this.$t('dashboardPage.Approvedsuccessfully').toString());
            this.$router.go(0);
        }
    }
    public async resend() {
        if (this.sender.requestStatus !== 3) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderRejected').toString());
            return;
        } else if (this.isFromDashboard) {
            const response = await this.dashboardService.resend(this.selectedIds, this.resendComment);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                Toaster.success(this.$t('dashboardPage.resentSuccessfully').toString());
                this.$router.go(0);
            }
        } else {
            const response = await this.UpdateDataRequestsService.resend(this.selectedIds);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                this.selectedIds = [];
                Toaster.success(this.$t('dashboardPage.resentSuccessfully').toString());
                this.$router.go(0);
            }
        }

    }

    public ConectivityDetail(senderId: string) {
        this.$router.push({ name: 'SenderConectivityDetail', params: { senderId } });
    }
    public get rejectReasonIsValid(): boolean {
        return ((this.selectedRejectReasonList.length !== 0 && this.selectedRejectReasonList.length !== undefined) || this.otherRejectResone !== '');
    }
    public async showRejectDialog() {
        this.rejectdialog = true;
    }
    public async hideRejectDialog() {
        this.isAllSelected = false;
        this.isOtherSelected = false;
        this.rejectResone = null;
        this.otherRejectResone = '';
        this.isItOtherReasonPorp = false;
        this.selectedRejectReasonList = [];
        this.rejectdialog = false;
    }
    public async reject() {
        this.rejectdialog = false;
        if (this.sender.requestStatus !== 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
            return;
        } else {
            if (this.selectedRejectReasonList.length !== undefined) {
                this.rejectResone = this.selectedRejectReasonList.slice().map((x) => x.rejectionText).toString();
            }
            this.rejectResone = (this.rejectResone === null || this.rejectResone === '') ? this.otherRejectResone : this.otherRejectResone != '' ? this.rejectResone + ',' + this.otherRejectResone : this.rejectResone;
            await this.dashboardService.reject(this.selectedIds, this.rejectResone);
            await this.dashboardService.rejectRequestEmail(this.selectedIds);
            this.rejectResone = '';
            this.selectedIds = [];
            Toaster.success(this.$t('dashboardPage.Rejectedsuccessfully').toString());
            this.$router.go(0);
        }
    }
    public async showSenderRequestTransactions() {
        var response = await this.senderInfoService.GetRequestApprovalHistory(this.id);
        if (response.isSuccess) {
            this.SenderRequestTransactions = response.result;
            this.showSenderRequestTransactionsdialog = true;
        }
    }
    public async showExtendRequests() {
        var response = await this.senderInfoService.getExtendRequestsHistory(this.id);
        if (response.isSuccess) {
            this.senderRequestExtendViewModel = response.result;
            this.showExtendRequestsdialog = true;
        }
    }
    public async hideshowSenderRequestTransactionsdialog() {
        this.showSenderRequestTransactionsdialog = false;
    }
    public async hideExtendRequestsdialog() {
        this.showExtendRequestsdialog = false;
    }
    public PreparePay() {
        if (this.sender.requestStatus !== 2) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderPendingPay').toString());
            return;
        } else {
            this.$router.push({ name: 'Payment', params: { ids: this.selectedIds.join(',') } });
        }
    }
    public async DownloadFile(file: AttachmentModel) {
        const fileContent = await this.senderInfoService.GetFileContent(file.id);
        const array = Buffer.from(fileContent, 'base64');
        const blob = new Blob([array], { type: 'application/pdf' });
        FileSaver.saveAs(blob, file.fileName);
    }
}
